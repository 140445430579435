import React, { useState, useRef, useEffect } from 'react'
 
const App = () => {
    // We need ref in this, because we are dealing
    // with JS setInterval to keep track of it and
    // stop it when needed
    const Ref = useRef(null);
 
    // The state for our timer
    const [timer, setTimer] = useState('...');
 
    const getTimeRemaining = (e) => {
        const total = Date.parse('2024-06-01') - Date.parse(new Date());
        const days = Math.floor(total / 1000 / 60 / 60 / 24);
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / 1000 / 60 / 60) % 24);
        return {
            total, days, hours, minutes, seconds
        };
    }
 
    const startTimer = (e) => {
        let { total, days, hours, minutes, seconds }
                    = getTimeRemaining(e);
        if (total >= 0) {
 
            // update the timer
            // check if less than 10 then we need to
            // add '0' at the beginning of the variable
            setTimer(
                days + (days == 1 ? ' day ' : ' days ') +
                hours + (hours == 1 ? ' hour ' : ' hours ') +
                minutes + ' min ' +
                seconds + ' sec'
            )
        }
    }
 
    const clearTimer = (e) => {
         // If you try to remove this line the
        // updating of timer Variable will be
        // after 1000ms or 1sec
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000)
        Ref.current = id;
    }
 
    const getDeadTime = () => {
        let deadline = new Date();
 
        // This is where you need to adjust if
        // you entend to add more time
        deadline.setSeconds(deadline.getSeconds() + 10);
        return deadline;
    }
 
    // We can use useEffect so that when the component
    // mount the timer will start as soon as possible
 
    // We put empty array to act as componentDid
    // mount only
    useEffect(() => {
        clearTimer(getDeadTime());
    }, []);
 
    // Another way to call the clearTimer() to start
    // the countdown is via action event from the
    // button first we create function to be called
    // by the button
    const onClickReset = () => {
        clearTimer(getDeadTime());
    }
 
    return (
        <div className="content">
            <div className="text-block">
                <div className="text small">Diana comes back in:</div>
                <div className="text">{timer}</div>
            </div>
        </div>
    )
}
 
export default App;